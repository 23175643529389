<template>
    <div class="index-box">
        <!-- 轮播 -->
        <div class="swiper-box">
            <swiper v-bind:options="swiperOption">
                <swiper-slide v-for="(banneritem,index) in swiperList" :key="index">
                   <div class="imgBox">
                       <img :src="banneritem.bannerImg" alt="">
                   </div>
                </swiper-slide>
                <!-- Optional controls -->
                <div class="swiper-pagination"  slot="pagination"></div>
                <!-- <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div> -->
            </swiper>
        </div>
        <!-- 课程中心 -->
        <div class="class-box">
            <div class="class-wrap">
                <div class="slide-title">
                    <div class="left-side">
                        <i></i>课程中心
                    </div>
                    <div>
                        <router-link to="classlist">更多>></router-link>
                        <!-- <a href="javascript:;">更多>></a> -->
                    </div>
                </div>
                <div class="slide-box">
                    <div v-if="courseList.length == 0" style="text-align: center;font-size: 24px;margin-top: 113px;">
                        暂无课程
                    </div>
                    <div>
                        <ul>
                            <li v-for="(course,index) in courseList" :key="index">
                                <a @click="jumpDetail(course.id)">
                                    <div class="courseslide">
                                        <img :src="course.logoUrl" alt="" v-if="course.logoUrl != null||course.logoUrl != undefined">
                                        <img src="../assets/img/default_csmall.jpg" alt="" v-else>
                                    </div>
                                    <div class="courseTitle">
                                        <p class="classTitle">{{course.name}}</p>
                                        <p class="classTitleColor" v-if="course.isPay == 1">¥{{course.price}}</p>
                                        <p class="classTitleColor" v-else-if="course.price == null && course.isPay == 1">¥0</p>
                                        <p class="classTitleColor" v-else>免费</p>
                                    </div>
                                    
                                    <p class="teacher">主讲老师：{{course.teacherName}}</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
        <!-- 直播试听 -->
        <div class="live-box">
            <div class="live-wrap">
                <div class="slide-title">
                    <div class="left-side">
                        <i></i>直播试听
                    </div>
                    <div>
                        <router-link to="Live">更多>></router-link>
                        <!-- <a href="javascript:;">更多>></a> -->
                    </div>
                </div>
                <div class="live-content">
                    <div v-if="liveList.length == 0" style="text-align: center;font-size: 24px;margin-top: 113px;">
                        暂无直播
                    </div>
                    <div v-else>
                        <ul>
                            <li v-for="(item, i) in liveList" :key="i">
                                <div class="img-box">
                                    <img :src="item.logoUrl" alt="" v-if="item.logoUrl != null||item.logoUrl != undefined">
                                    <img src="../assets/img/default_csmall.jpg" alt="" v-else>
                                </div>
                                <div class="live-content-box">
                                    <div class="live-class-title">
                                        <p>{{item.title}}</p>
                                    </div>
                                    <div class="live-time">
                                        <p>即将直播：{{item.startTime}}</p>
                                    </div>
                                    <div class="live-bottom-box">
                                        <div class="live-teacher-box">
                                            <div class="live-img">
                                                <img :src="item.teacherLogoUrl" alt="" v-if="item.teacherLogoUrl != ''|| item.teacherLogoUrl != undefined">
                                                <img src="../assets/img/headIcon.jpg" alt="" v-else>
                                            </div>
                                            <p class="live-teacher">{{item.teacherName}}</p>
                                        </div>
                                        <div class="live-button">
                                            <router-link :to="{name: 'livedetail',query:{liveId: item.id}}">进入直播</router-link>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- 网校云名师 -->
        <div class="teacher-box">
            <div class="teacher-wrap">
                <div class="teacher-title">
                    <div class="teacher-slide">
                        <i></i>网校云名师
                    </div>
                </div>
                <div class="teacher-content">
                    <ul class="teacher-ul">
                        <li class="teacher-li" v-for="(teacher, index) in teacherList" :key="index">
                            <div class="img">
                                <img :src="teacher.teacherImg" alt="dasd">
                                <div v-on:mouseover="changeActive($event)" v-on:mouseout="removeActive($event)">
                                    <p class="name-title">{{teacher.teacherName}}</p>
                                    <p class="name-subtitle">{{teacher.teahcerTitle}}</p>
                                    <p class="name-content" v-html="teacher.teacherIntroduce">
                                        {{teacher.teacherIntroduce}}
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 考试辅导书 -->
        <!-- <div class="book-box">
            <div class="book-wrap">
                <div class="book-title">
                    <div>
                        <i></i>考试辅导书
                    </div>
                    <div>
                        <a href="javascript:;">更多>></a>
                    </div>
                </div>
                <div class="book-wrap">
                    <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            </div>
        </div> -->
        <!-- 政策和经验 -->
        <div class="together-box">
            <div class="together-wrap">
                <div class="poilcy-box">
                    <div class="poilcy-title">
                        <div class="word">
                            <i></i>政策资讯
                        </div>
                        <div>
                            <router-link to="news">更多></router-link>
                        </div>
                    </div>
                    <div class="poilcy-content">
                        <div v-if="policyList.length == 0">
                            <p style="font-size: 18px;color: #333;text-align: center;margin-top: 90px;">暂无资讯</p>
                        </div>
                        <div v-else>
                            <div class="hot-message" @click="learnNow(policyData.id)">
                                <div class="img">
                                    <img :src="policyData.logoUrl" alt="">
                                </div>
                                <div class="message-detail">
                                    <p class="detail-title">{{policyData.title}}</p>
                                    <p class="detail-word">{{policyData.description}}</p>
                                    <p class="detail-date">{{policyData.publishDate | formatDate}}</p>
                                </div>
                            </div>
                            <div class="news-list">
                                <ul>
                                    <li v-for="(item,index) in policyList" :key="index">
                                        <a href="javascript:;" @click="learnNow(item.id)">
                                            <p>{{item.title}}</p>
                                            <p>{{item.publishDate | formatDate}}</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="exam-box">
                     <div class="poilcy-title">
                        <div class="word">
                            <i></i>考试经验
                        </div>
                        <div>
                            <router-link to="news">更多></router-link>
                        </div>
                    </div>
                    <div class="poilcy-content">
                        <div v-if="expList.length == 0">
                            <p style="font-size: 18px;color: #333;text-align: center;margin-top: 90px;">暂无资讯</p>
                        </div>
                        <div v-else>
                            <div class="hot-message" @click="learnNow(policyData.id)">
                                <div class="img">
                                    <img :src="expData.logoUrl" alt="">
                                </div>
                                <div class="message-detail">
                                    <p class="detail-title">{{expData.title}}</p>
                                    <p class="detail-word">{{expData.description}}</p>
                                    <p class="detail-date">{{expData.publishDate | formatDate}}</p>
                                </div>
                            </div>
                            <div class="news-list">
                                <ul>
                                    <li v-for="(item,index) in expList" :key="index">
                                        <a href="javascript:;" @click="learnNow(item.id)">
                                            <p>{{item.title}}</p>
                                            <p>{{item.publishDate | formatDate}}</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    name: 'index',
    data() {
        return {
            cur: 0, //默认选中第一个tab
            swiperOption:{
                // autoplay:true,
                loop:true,
                // effect:'cube',
                // cubeEffect: {
                //     shadowOffset: 100,
                //     shadowScale: 0.6
                // }
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    type: "bullets"
                }

            },
            swiperList: [
                {
                    bannerImg: require('../assets/img/banner.jpg')
                },
                {
                    bannerImg: require('../assets/img/banner2.jpg')
                }
            ],
            courseList: [],
            teacherList: [
                {
                    teacherImg: require('../assets/img/hexiaoen.jpg'),
                    teacherName: '何晓恩',
                    teahcerTitle: '入户考证培训资深讲师',
                    teacherIntroduce: '从事入户考证培训5年，擅长寓教于乐，善于运用技巧解题，课堂气氛活跃，授课经验丰富，对待学员疑问耐心解答，深受学员喜爱。'
                },
                {
                    teacherImg: require('../assets/img/lingrongyan.jpg'),
                    teacherName: '凌榕艳',
                    teahcerTitle: '入户考证培训资深讲师',
                    teacherIntroduce: '从事入户考证培训4年，累计培训近5000名学员，擅长考题分析，有独特的解题技巧。喜欢课堂互动，授课深入浅出，广受学员好评。'
                },
                {
                    teacherImg: require('../assets/img/longweiyu.jpg'),
                    teacherName: '龙薇羽',
                    teahcerTitle: '入户考证培训资深讲师',
                    teacherIntroduce: '专注研究入户考证培训，从事系统集成、中级经济师考试研发及教学工作。善于做考题分析且有独特见解，课堂气氛生动有趣，深受学员喜爱。'
                },
                {
                    teacherImg: require('../assets/img/panyujuan.jpg'),
                    teacherName: '潘玉娟',
                    teahcerTitle: '入户考证培训资深讲师',
                    teacherIntroduce: '专业从事系统集成、信息系统考试研发及教学工作，理论知识扎实。耐心解决每一位学员的疑问，广受学员好评。'
                }
            ],
            liveList: [],
            policyList: [],
            policyData: {},
            expList: [],
            expData: {},
            type: 2
        }
    },
    metaInfo: {
        title: '首页-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    components: {
        Swiper,
        SwiperSlide
    },
    filters: {
        formatDate(time) {
            var date = new Date(time);
            var month= date.getMonth()+1;
            var day= date.getDate();
            return month.toString().padStart(2,'0')+"/"+day.toString().padStart(2,'0')
        }
    },
    mounted() {
        // this.bannerList();
        this.course();
        // this.teacher();
        this.live();
        this.getPolicy();
        this.getExp();
    },
    methods:{
        learnNow(id) {
            this.$router.push({
                name: 'newsdetail',
                params:{id,ctype: this.type}
            })
        },
        getExp() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/information/page.html',
                data: {
                    categoryId: 2
                }
            }).then((res) => {
                let arrList = res.data.result.list.slice(0,5);
                for(let i = 0;i<arrList.length;i++) {
                    this.expData = arrList[0]
                }
                this.expList = arrList.slice(1,5);
            })
        },
        getPolicy() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/information/page.html',
                data: {
                    categoryId: 1
                }
            }).then((res) => {
                let arrlist = res.data.result.list.slice(0,5);
                for(let i = 0;i<arrlist.length;i++) {
                    this.policyData = arrlist[0];
                    break;
                }
                this.policyList = arrlist.slice(1,5);
            })
        },
        jumpDetail(id) {
            this.$router.push({
                name:'classdetail',
                params: {
                    "id":id
                }
            })
        },
        // // 轮播列表
        // bannerList(){
        //     this.axios({
        //         method: 'POST',
        //         url: 'api/v2/yun/index/info.html'
        //     }).then((res) => {
        //         console.log(res);
        //         this.swiperList = res.data.result.bannerList;
        //     })
        // },
        // 课程列表
        course() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/course/index.html'
            }).then((res) => {
                this.courseList = res.data.result.list;
            })
        },
        // 老师列表
        // teacher() {
        //     this.axios({
        //         method: 'POST',
        //         url: 'api/v2/yun/teacher/index.html'
        //     }).then((res) => {
        //         this.teacherList = res.data.result.list;
        //     })
        // },
        // 直播试听
        live() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/live/index.html'
            }).then((res) => {
                this.liveList = res.data.result.list;
            })
        },
        // 鼠标移入加入class
        changeActive($event){
            $event.currentTarget.className="active";
        },
        removeActive($event){
            $event.currentTarget.className="";
        }
    }
}
</script>

<style lang="scss">
    .index-box {
        margin-top: 100px;
        width: 100%;
        min-width: 1200px;
        .swiper-box {
            height: 465px;
            margin-bottom: 114px;
            .imgBox {
                width: 100%;
                height: 465px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .swiper-pagination-bullet {
                width: 10px;
                height: 10px;
                background: #FFFFFF;
            }
        }
        // 课程中心
        .class-box {
            padding-bottom: 60px;
            .class-wrap {
                margin: 0 auto;
                width: 1200px;
                .slide-title {
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px solid #DEDEDE;
                    box-sizing: border-box;
                    padding-bottom: 8px;
                    align-items: flex-end;
                    .left-side {
                        display: flex;
                        align-items: center;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        font-size: 24px;
                        color: #333;
                        i {
                            margin-right: 10px;
                            display: inline-block;
                            width: 21px;
                            height: 25px;
                            background: url("../assets/img/classCenter.png") no-repeat;
                            vertical-align: middle;
                        }
                    }
                    a {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        color: #999999;
                    }
                }
                .slide-box {
                    ul {
                        display: flex;
                        // justify-content: space-between;
                        flex-wrap: wrap;
                        li {
                            margin-right: 10px;
                            margin-bottom: 20px;
                            width: 288px;
                            height: 214px;
                            border: 1px solid #EAEAEA;
                            border-radius: 0px 0px 6px 6px;
                            cursor: pointer;
                            a {
                                display: inline-block;
                                .courseslide {
                                    margin-bottom: 10px;
                                    width: 288px;
                                    height: 152px;
                                    // background: pink;
                                }
                                .courseTitle {
                                    display: flex;
                                    .classTitle {
                                        width: 168px;
                                        padding-left: 16px;
                                        margin-bottom: 3px;
                                        font-size: 14px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #333333;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        white-space: nowrap;
                                    }
                                    .classTitleColor {
                                        margin-left: 43px;
                                        color: #FF5A74;
                                        font-size: 14px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                    }
                                }
                                .teacher {
                                    padding-left: 16px;
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #999999;
                                }
                            }
                        }
                    }
                }
            }
        }
        // 直播中心
        .live-box {
            padding-top: 80px;
            box-sizing: border-box;
            height: 549px;
            background: #F5F5F5;
            .live-wrap {
                margin: 0 auto;
                width: 1200px;
                .slide-title {
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px solid #DEDEDE;
                    box-sizing: border-box;
                    padding-bottom: 8px;
                    align-items: flex-end;
                    .left-side {
                        display: flex;
                        align-items: center;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        font-size: 24px;
                        color: #333;
                        i {
                            margin-right: 10px;
                            display: inline-block;
                            width: 21px;
                            height: 25px;
                            background: url("../assets/img/classCenter.png") no-repeat;
                            vertical-align: middle;
                        }
                    }
                    a {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        color: #999999;
                    }
                }
                .live-content {
                    ul {
                        display: flex;
                        li {
                            width: 280px;
                            height: 322px;
                            background: #FFFFFF;
                            border-radius: 6px;
                            margin-right: 27px;
                            &:last-child {
                                margin-right: 0;
                            }
                            .img-box {
                                width: 280px;
                                height: 180px;
                                // background: blue;
                                border-radius: 6px 6px 0px 0px;
                            }
                            .live-content-box {
                                padding-top: 18px;
                                padding-left: 20px;
                                padding-right: 20px;
                                box-sizing: border-box;
                                .live-class-title {
                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #333333;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                }
                                .live-time {
                                    margin-bottom: 16px;
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #999999;
                                }
                                .live-bottom-box {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    .live-teacher-box {
                                        .live-img {
                                            margin: 0 auto;
                                            width: 30px;
                                            height: 30px;
                                            border-radius: 50%;
                                        }
                                        .live-teacher {
                                            font-size: 14px;
                                            font-family: Microsoft YaHei;
                                            font-weight: 400;
                                            color: #999999;
                                        }
                                    }
                                    .live-button {
                                        a {
                                            display: block;
                                            width: 100px;
                                            height: 36px;
                                            background: #FA4B66;
                                            border-radius: 18px;
                                            line-height: 36px;
                                            text-align: center;
                                            font-size: 14px;
                                            font-family: Microsoft YaHei;
                                            font-weight: 400;
                                            color: #FFFFFF;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        // 网校云名师
        .teacher-box {
            padding-top: 81px;
            box-sizing: border-box;
            height: 475px;
            .teacher-wrap {
                margin: 0 auto;
                width: 1200px;
                .teacher-title {
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px solid #DEDEDE;
                    padding-bottom: 8px;
                    .teacher-slide {
                        display: flex;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        font-size: 24px;
                        color: #333;
                        i {
                            margin-right: 10px;
                            display: inline-block;
                            width: 26px;
                            height: 22px;
                            margin-top: 6px;
                            background: url("../assets/img/teacherIcon.png") no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                }
                .teacher-content {
                    .teacher-ul {
                        display: flex;
                        justify-content: space-between;
                        .teacher-li {
                            .img {
                                position: relative;
                                width: 285px;
                                height: 330px;
                                overflow: hidden;
                                border: 1px solid #dedede;
                                div {
                                    padding-left: 18px;
                                    padding-right: 15px;
                                    padding-top: 45px;
                                    box-sizing: border-box;
                                    position: absolute;
                                    left: 0;
                                    bottom: -73px;
                                    width: 285px;
                                    height: 195px;
                                    background: url('../assets/img/teacherIntroduceBox.png') center no-repeat;
                                    cursor: pointer;
                                    .name-title {
                                        margin-bottom: 7px;
                                        text-align: center;
                                        font-size: 20px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #333333;
                                    }
                                    .name-subtitle {
                                        margin-bottom: 10px;
                                        text-align: center;
                                        font-size: 14px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #999999;
                                    }
                                    .name-content {
                                        border-top: 1px solid #EEEEEE;
                                        padding-top: 11px;
                                        box-sizing: border-box;
                                        background: none;
                                        font-size: 14px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #666666;
                                    }
                                }
                                .active {
                                    position: absolute;
                                    bottom: 0;
                                }
                            }
                            
                        }
                    }
                }
            }
        }
        // 考试辅导书
        .book-box {
            padding-top: 80px;
            box-sizing: border-box;
            height: 549px;
            background: #F5F5F5;
            .book-wrap {
                margin: 0 auto;
                width: 1200px;
                background: yellow;
                .book-title {
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: space-between;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    font-size: 24px;
                    color: #333;
                    i {
                        display: inline-block;
                        width: 21px;
                        height: 25px;
                        background: yellow;
                        vertical-align: middle;
                    }
                    a {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        color: #999999;
                    }
                }
                .book-wrap {
                    ul {
                        display: flex;
                        justify-content: space-between;
                        li {
                            width: 275px;
                            height: 250px;
                            // background: blue;
                        }
                    }
                }
            }
        }
        // 政策和经验
        .together-box {
            padding-top: 80px;
            box-sizing: border-box;
            height: 527px;
            .together-wrap {
                margin: 0 auto;
                width: 1200px;
                display: flex;
                justify-content: space-between;
                .poilcy-box {
                    margin-right: 15px;
                    .poilcy-title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-bottom: 9px;
                        margin-bottom: 30px;
                        width: 585px;
                        border-bottom: 1px solid #DEDEDE;
                        .word {
                            font-size: 24px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #333333;
                            i {
                                margin-right: 10px;
                                margin-top: -5px;
                                display: inline-block;
                                width: 22px;
                                height: 23px;
                                background: url("../assets/img/infoIcon.png") no-repeat;
                                vertical-align: middle;
                            }
                        }
                        a {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            color: #999999;
                        }
                    }
                    .poilcy-content {
                        width: 585px;
                        .hot-message {
                            display: flex;
                            justify-content: space-between;
                            .img {
                                width: 230px;
                                height: 136px;
                            }
                            .message-detail {
                                width: 325px;
                                cursor: pointer;
                                .detail-title {
                                    margin-bottom: 13px;
                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #333333;
                                    line-height: 24px;
                                    &:hover {
                                        color: #FF5A74;
                                    }
                                }
                                .detail-word {
                                    margin-bottom: 13px;
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #999999;
                                    line-height: 20px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 2;
                                }
                                .detail-date {
                                    float: right;
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #333333;
                                }
                            }
                        }
                        .news-list {
                            ul {
                                li {
                                    a {
                                        display: flex;
                                        justify-content: space-between;
                                        p {
                                            font-size: 14px;
                                            font-family: Microsoft YaHei;
                                            font-weight: 400;
                                            color: #666666;
                                            line-height: 40px;
                                            cursor: pointer;
                                            &:hover {
                                                color:#FF5A74;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .exam-box {
                    .poilcy-title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-bottom: 9px;
                        margin-bottom: 30px;
                        width: 585px;
                        border-bottom: 1px solid #DEDEDE;
                        .word {
                            font-size: 24px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #333333;
                            i {
                                margin-right: 10px;
                                margin-top: -5px;
                                display: inline-block;
                                width: 23px;
                                height: 23px;
                                background: url("../assets/img/examIcon.png") no-repeat;
                                vertical-align: middle;
                            }
                        }
                        a {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            color: #999999;
                        }
                    }
                    .poilcy-content {
                        width: 585px;
                        .hot-message {
                            display: flex;
                            justify-content: space-between;
                            .img {
                                width: 230px;
                                height: 136px;
                            }
                            .message-detail {
                                width: 325px;
                                cursor: pointer;
                                .detail-title {
                                    margin-bottom: 13px;
                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #333333;
                                    line-height: 24px;
                                    &:hover {
                                        color: #FF5A74;
                                    }
                                }
                                .detail-word {
                                    margin-bottom: 13px;
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #999999;
                                    line-height: 20px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 2;
                                }
                                .detail-date {
                                    float: right;
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #333333;
                                }
                            }
                        }
                        .news-list {
                            ul {
                                li {
                                    a {
                                        display: flex;
                                        justify-content: space-between;
                                        p {
                                            font-size: 14px;
                                            font-family: Microsoft YaHei;
                                            font-weight: 400;
                                            color: #666666;
                                            line-height: 40px;
                                            cursor: pointer;
                                            &:hover {
                                                color:#FF5A74;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }
        }
    }
</style>